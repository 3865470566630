import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { isObservable, Observable, of } from 'rxjs';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { normalizeUrl } from '@tremaze/shared/util-http';
import { first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BaseInterceptor implements HttpInterceptor {
  constructor(private appConfigService: AppConfigService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const basePath$ = isObservable(this.appConfigService.basePath)
      ? this.appConfigService.basePath
      : of(this.appConfigService.basePath);
    return basePath$.pipe(
      first(),
      switchMap((basePath) => {
        if (!request.url.startsWith('http')) {
          basePath = normalizeUrl(basePath);
          let url = request.url;
          if (!url.startsWith('/')) {
            url = `/${url}`;
          }
          const preferredLang = navigator.language;
          request = request.clone({
            url: normalizeUrl(`${basePath}${url}`),
            headers: request.headers.set('X-Forwarded-Proto', 'https'),
            params: request.params.set('lang', preferredLang),
          });
        }
        return next.handle(request);
      })
    );
  }
}
