import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<OIDCUser>>()
class OIDCUser {
  constructor(
    readonly sub: string,
    readonly tremazeUserId: string,
    readonly name: string,
    readonly preferredUsername: string,
    readonly givenName: string,
    readonly familyName: string,
    readonly email: string,
    readonly emailVerified: boolean
  ) {}

  static deserialize(data) {
    return !data
      ? null
      : new OIDCUser(
          data.sub,
          data.tremazeUserId,
          data.name,
          data.preferred_username,
          data.given_name,
          data.family_name,
          data.email,
          data.email_verified
        );
  }
}
