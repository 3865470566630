import { Equatable } from '@tremaze/shared/models';
import {
  combineLatest,
  concatMap,
  delay,
  dematerialize,
  distinctUntilChanged,
  from,
  materialize,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  skip,
  startWith,
  switchMap,
  timer,
  toArray,
} from 'rxjs';
import { catchError, delayWhen, every, filter, map, tap } from 'rxjs/operators';

export function createObservableWithMinDuration<T, R>(
  o$: Observable<T>,
  t = 1000,
  onError?: (err) => R
): Observable<T> {
  return combineLatest([
    timer(t),
    o$.pipe(
      catchError((e) => {
        if (onError) {
          return of(onError(e));
        }
        return of(null);
      })
    ),
  ]).pipe(map((r) => r[1] as T));
}

export function doOnError<T>(callback: (err) => void): OperatorFunction<T, T> {
  return (ob$: Observable<T>) =>
    ob$.pipe(
      catchError((err) => {
        callback(err);
        throw err;
      })
    );
}

export function catchErrorSync<T, R>(
  handler: (err) => R
): OperatorFunction<T, T | R> {
  return (ob$: Observable<T>) =>
    ob$.pipe(catchError((err) => of(handler(err))));
}

export function catchErrorMapTo<T, R>(to: R): OperatorFunction<T, R | T> {
  return (ob$: Observable<T>) => ob$.pipe(catchError(() => of(to)));
}

export function negateBool(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) => ob$.pipe(map((value) => !value));
}

export function filterTrue(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) =>
    ob$.pipe(filter((value) => value === true));
}

export function filterFalse(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) =>
    ob$.pipe(filter((value) => value === false));
}

export function filterNotNullOrUndefined<T>(): OperatorFunction<
  T | null | undefined,
  T
> {
  return (ob$: Observable<T | null | undefined>) =>
    ob$.pipe<T>(filter((value) => value !== null && value !== undefined));
}

export function filterMinLength(
  minLength: number
): OperatorFunction<string, string> {
  return (ob$: Observable<string>) =>
    ob$.pipe(filter((value) => value.length >= minLength));
}

export function everyTrue(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) => ob$.pipe(every((v) => v === true));
}

export function everyFalse(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) => ob$.pipe(every((v) => v === false));
}

export function anyTrue(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) => ob$.pipe(everyFalse(), negateBool());
}

export function anyFalse(): OperatorFunction<boolean, boolean> {
  return (ob$: Observable<boolean>) => ob$.pipe(everyTrue(), negateBool());
}

export function mapEveryTrue(): OperatorFunction<boolean[], boolean> {
  return (ob$: Observable<boolean[]>) =>
    ob$.pipe(map((e) => e.every((v) => v === true)));
}

export function mapAnyTrue(): OperatorFunction<boolean[], boolean> {
  return (ob$: Observable<boolean[]>) =>
    ob$.pipe(map((e) => e.some((v) => v === true)));
}

export function mapEveryFalse(): OperatorFunction<boolean[], boolean> {
  return (ob$: Observable<boolean[]>) =>
    ob$.pipe(map((e) => e.every((v) => v === false)));
}

export function mapEquals<T>(value: unknown): OperatorFunction<T, boolean> {
  return (ob$: Observable<T>) => ob$.pipe(map((e) => e === value));
}

export function mapNullOrUndefined<T>(): OperatorFunction<T, boolean> {
  return (ob$: Observable<T>) =>
    ob$.pipe(map((e) => e === null || e === undefined));
}

export function mapNotNullOrUndefined<T>(): OperatorFunction<T, boolean> {
  return (ob$: Observable<T>) =>
    ob$.pipe(map((e) => e !== null && e !== undefined));
}

export function mapFilterOutDuplicatesArray<T>() {
  return (ob$: Observable<T[]>) => ob$.pipe(map((e) => Array.from(new Set(e))));
}

export function ignoreErrors<T>(): OperatorFunction<T, T> {
  return (ob$: Observable<T>) =>
    ob$.pipe(
      materialize(),
      filter((e) => !e.error),
      dematerialize()
    );
}

export function mapNotEmpty<T>(): OperatorFunction<
  T[] | string | null | undefined,
  boolean
> {
  return (ob$: Observable<T[] | string | null | undefined>) =>
    ob$.pipe(map((e) => e !== null && e !== undefined && e.length > 0));
}

export function mapEmpty<T>(): OperatorFunction<
  T[] | string | null | undefined,
  boolean
> {
  return (ob$: Observable<T[] | string | null | undefined>) =>
    ob$.pipe(map((e) => e === null || e === undefined || e.length === 0));
}

/**
 * Maps an array of objects to a set of values by a key
 * @param key The key to map by
 */
export function mapToSetByKey<T>(
  key: keyof T
): OperatorFunction<T[], Set<T[keyof T]>> {
  return (ob$: Observable<T[]>) =>
    ob$.pipe(
      map((e) => e.map((item) => item[key])),
      map((e) => new Set(e))
    );
}

/**
 * Filters an array of objects by a set of values
 * @param key The key to filter by
 * @param set The set of values to filter by
 * @param negate Negate the filter
 */
export function filterBySet<T>(
  key: keyof T,
  set: Set<T[keyof T]>,
  negate = false
): OperatorFunction<T[], T[]> {
  return (ob$: Observable<T[]>) =>
    ob$.pipe(map((e) => e.filter((item) => negate !== set.has(item[key]))));
}

export function mapIncludes<T>(val: T): OperatorFunction<Iterable<T>, boolean> {
  return (ob$: Observable<Iterable<T>>) =>
    ob$.pipe(
      map((r) => [...r]),
      map((e) => e !== null && e !== undefined && e.includes(val))
    );
}

export function mapIncludesEquatable<T extends Equatable>(
  val: T
): OperatorFunction<Iterable<T>, boolean> {
  return (ob$: Observable<Iterable<T>>) =>
    ob$.pipe(
      map((r) => [...r]),
      map(
        (e) =>
          e !== null && e !== undefined && e.some((item) => item.equals(val))
      )
    );
}

export function distinctUntilSomeKeysChanged<T>(
  ...keys: (keyof T)[]
): OperatorFunction<T, T> {
  return (ob$: Observable<T>) =>
    ob$.pipe(
      distinctUntilChanged((x, y) => {
        return keys.some((k) => x[k] === y[k]);
      })
    );
}

export function distinctUntilArrayChanged<T>(): MonoTypeOperatorFunction<T[]> {
  return (ob$: Observable<T[]>) =>
    ob$.pipe(
      distinctUntilChanged((x, y) => {
        return x.length === y.length && x.every((v, i) => v === y[i]);
      })
    );
}

export function distinctUntilArrayKeyChanged<T>(
  key: keyof T
): OperatorFunction<T[], T[]> {
  return (ob$: Observable<T[]>) =>
    ob$.pipe(
      distinctUntilChanged((x, y) => {
        return x.length === y.length && x.every((v, i) => v[key] === y[i][key]);
      })
    );
}

export function delayFirst<T>(t: number, n = 1): MonoTypeOperatorFunction<T> {
  return (ob$: Observable<T>) => ob$.pipe(delay(t), startWith(null), skip(n));
}

/**
 * Ensures that the emission of the observable takes at least `minTime` milliseconds.
 * If the observable takes longer, it won't delay it further.
 *
 * @param {number} minDurationMs - The minimum time in milliseconds the observable should take to emit a value.
 * @return A pipeable RxJS operator function.
 */
export function minTimeDelay<T>(minTime: number): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    new Observable<T>((observer) => {
      const startTime = Date.now(); // Record the start time

      return source.pipe(
        delayWhen(() => {
          const elapsed = Date.now() - startTime;
          const delayFor = Math.max(0, minTime - elapsed);
          return timer(delayFor);
        }),
        catchError((error) => {
          const elapsed = Date.now() - startTime;
          const delayFor = Math.max(0, minTime - elapsed);
          return timer(delayFor).pipe(
            // throwError must be passed a function that returns an error, not called directly.
            tap(() =>  {
              throw error;
            })
          );
        }),
      ).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    });
}

/**
 * An RxJS operator that transforms each element of an array using a provided mapper function
 * and accumulates the results into a single array.
 *
 * @param transformer A function that takes an item of type T and returns an Observable of type R.
 * @returns An RxJS OperatorFunction that transforms and accumulates an array of items.
 */
export function transformAndAccumulateArray<T, R>(transformer: (item: T) => Observable<R>): OperatorFunction<T[], R[]> {
  return (source$) => source$.pipe(
    switchMap(items => {
      return from(items).pipe(
        concatMap(item => transformer(item).pipe(catchError(() => of(null)))),
        filter(value => value !== null),
        toArray()
      );
    })
  );
}

export abstract class TremazeRxjsUtilities {
  /***
   * @deprecated Use global function (see above) instead
   */
  static createObservableWithMinDuration<T>(
    o$: Observable<T>,
    t = 1000
  ): Observable<T> {
    return combineLatest([
      timer(t),
      o$.pipe(
        catchError((e) => {
          console.error(e);
          return of(null);
        })
      ),
    ]).pipe(map((r) => r[1]));
  }
}
