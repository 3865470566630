import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthenticatedUser,
  TokenInfo,
} from '@tremaze/shared/core/auth-v2/types';

@Injectable({ providedIn: 'root' })
export abstract class AuthV2DataSource {
  abstract loginWithUsernamePassword(
    username: string,
    password: string
  ): Observable<TokenInfo>;

  abstract getRefreshedToken(refreshToken: string): Observable<TokenInfo>;

  abstract getAuthenticatedUser(): Observable<AuthenticatedUser>;
}
