import { take } from 'rxjs/operators';
import { Injectable, Optional } from '@angular/core';
import { firstValueFrom, isObservable } from 'rxjs';
import {
  PrivilegeName,
  TzPermissionRequest,
  TzPermissionRequestIPerms,
} from '@tremaze/shared/permission/types';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Injectable({ providedIn: 'root' })
export class PermissionCheckService {
  constructor(
    private _authService: AuthV2Service,
    @Optional() private readonly appConfigService: AppConfigService
  ) {}

  async checkPermission$(perms: TzPermissionRequest): Promise<boolean> {
    if (typeof perms === 'boolean') {
      return perms;
    }
    if (this.appConfigService?.disablePermissionChecks) {
      return true;
    }
    const user = await firstValueFrom(this._authService.authenticatedUser$);

    function checkInstPerms(
      instIds$: string[] | string,
      instPerms: PrivilegeName[][]
    ): boolean {
      if (instIds$ === 'ANY') {
        return checkInstPerms(
          user.userInstitutions.map((u) => u.institution?.id),
          instPerms
        );
      }
      const instIds = instIds$ instanceof Array ? instIds$ : [instIds$];
      return instIds.some((instId) => {
        const userInstitution = user.userInstitutions.find(
          (uI) => uI.institution?.id === instId
        );
        if (userInstitution) {
          const userInstitutionPrivileges = userInstitution.distinctPrivileges;
          return instPerms.some((permissionGroup) =>
            permissionGroup.every((permission) =>
              userInstitutionPrivileges?.has(permission)
            )
          );
        }
        return false;
      });
    }

    if (perms && user) {
      let gPermsPassed = false;
      // Global permssions
      if (perms.gPerms && user.userPrivileges) {
        const gPerms: PrivilegeName[][] = (
          Array.isArray(perms.gPerms) ? perms.gPerms : [[perms.gPerms]]
        ) as PrivilegeName[][];
        gPermsPassed =
          typeof gPerms === 'boolean'
            ? gPerms
            : gPerms.some((permissionGroup) =>
                permissionGroup.every((permission) =>
                  user.userPrivileges.includes(permission)
                )
              );
      }
      if (gPermsPassed) {
        return true;
      }
      // Inst permissions
      if (user.userInstitutions?.length && perms.iPerms) {
        let iPerms: TzPermissionRequestIPerms[] = [];
        if (typeof perms.iPerms === 'boolean') {
          return perms.iPerms;
        } else if (Array.isArray(perms.iPerms)) {
          iPerms = perms.iPerms;
        } else {
          iPerms = [
            {
              perms: perms.iPerms.perms ?? perms.gPerms,
              instIds: perms.iPerms.instIds,
            },
          ];
        }
        for (const perm of iPerms) {
          let instIds;
          if (isObservable(perm.instIds)) {
            instIds = await perm.instIds?.pipe(take(1)).toPromise();
          } else {
            instIds = perm.instIds;
          }
          const iPermPerms = Array.isArray(perm.perms)
            ? perm.perms
            : [[perm.perms]];
          if (checkInstPerms(instIds, iPermPerms as PrivilegeName[][])) {
            return true;
          }
        }
      }
      return false;
    }
    return true;
  }
}
