import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationPayload } from '@tremaze/shared/feature/confirmation/types';

@Component({
  selector: 'tremaze-confirmation',
  template: `
    <h3 [innerHTML]="title" mat-dialog-title></h3>
    <div [innerHTML]="text" mat-dialog-content></div>
    <div align="end" mat-dialog-actions>
      <button
        [mat-dialog-close]="false"
        cdkFocusInitial
        mat-dialog-close
        mat-stroked-button
      >
        {{ denyButtonText }}
      </button>
      <button
        [color]="warn ? 'warn' : 'accent'"
        [mat-dialog-close]="true"
        mat-flat-button
      >
        {{ confirmButtonText }}
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  title = 'Aktion bestätigen';
  text = '<p>Möchtest du das wirklich tun?</p>';
  denyButtonText = 'Nein';
  confirmButtonText = 'Ja';
  warn = false;

  constructor(@Inject(MAT_DIALOG_DATA) @Optional() data?: ConfirmationPayload) {
    this.title = data?.title || this.title;
    this.text = data?.text || this.text;
    this.denyButtonText = data?.denyButtonText || this.denyButtonText;
    this.confirmButtonText = data?.confirmButtonText || this.confirmButtonText;
    this.warn = data?.warn || false;
  }
}
