import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const msie11 = ua.indexOf('Trident/');
  return msie > 0 || msie11 > 0;
};

export function MSALClientFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '722e10bd-1336-4d8c-8262-adcca74bf3e3',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE(),
    },
  });
}

@NgModule({
  imports: [CommonModule, MsalModule],
})
export class SharedCoreMsalModule {
  static forRoot() {
    return {
      ngModule: SharedCoreMsalModule,
      providers: [
        MsalService,
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALClientFactory,
        },
      ],
    };
  }
}
