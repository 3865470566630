import { IdObject } from '@tremaze/shared/util/id-object';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidatorFn,
} from '@ngneat/reactive-forms';

export interface FormBuilderElement<T extends IdObject>
  extends FormInputConfig {
  control: AbstractControl<keyof T>;
  key: keyof T;
  group?: FormGroup<FormBuildable>;
  dataSource?: () => any;
}

export interface FormInputConfig {
  multiple?: boolean;
  required?: boolean;
  maxLength?: number;
  fileSelectorConfig?: {
    systemDirsType?: string;
    entity?: string;
    aspectRatio?: number;
  };
  inputType?: FormBuilderInputTypes;
  label?: string;
  customValidators?: [ValidatorFn[], AsyncValidatorFn[]] | [ValidatorFn[]];
}

export const FORM_BUILDER_DEFAULT_IGNORE_FIELDS = [
  'id',
  'meta',
  'isAccountNonExpired',
  'isAccountNonLocked',
  'isCredentialsNonExpired',
  'lastLogin',
];

export const FORM_BUILDER_KEY_DEFAULT_LOCALIZATIONS = {
  username: 'Benutzername',
  gender: 'Geschlecht',
  contact: 'Kontaktdaten',
  firstName: 'Vorname',
  lastName: 'Nachname',
  profileImage: 'Profilbild',
};

export type FormBuilderInputTypes =
  | 'TEXT'
  | 'EMAIL'
  | 'PHONE'
  | 'TEXTAREA'
  | 'RICH_TEXT'
  | 'CHECKBOX'
  | 'DATE'
  | 'IMAGE'
  | 'SELECT'
  | 'GROUP';

/***
 * @deprecated This adventure went nowhere and is now discontinued
 */
export abstract class FormBuildable implements IdObject {
  abstract id: string;

  getFormFieldConfigForKeyWithDefaults(key: keyof this): FormInputConfig {
    const config = this.getFormInputConfigForKey?.(key) || {};
    if (this[key.toString()] instanceof FormBuildable) {
      config.inputType = 'GROUP';
    } else {
      config.inputType = config.inputType || 'TEXT';
    }
    config.label = config.label || key?.toString();
    return config;
  }

  shouldFormBuilderIgnoreFieldWithDefaults(key: keyof this): boolean {
    return (
      this.shouldFormBuilderIgnoreField(key) ||
      FORM_BUILDER_DEFAULT_IGNORE_FIELDS.includes(key.toString())
    );
  }

  protected abstract getFormInputConfigForKey(key: keyof this): FormInputConfig;

  protected abstract shouldFormBuilderIgnoreField(key: keyof this): boolean;
}
