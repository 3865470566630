import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthV2Service } from './auth-service';
import { TenantConfigService } from '@tremaze/shared/tenant-config';
import { map } from 'rxjs/operators';
import { mapEveryTrue } from '@tremaze/shared/util/rxjs';

@Injectable({ providedIn: 'root' })
export class TenantSelectedGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthV2Service,
    private configService: TenantConfigService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.authService.hasActiveTenant$]).pipe(
      mapEveryTrue(),
      map((r) => {
        if (r) {
          return true;
        }
        return this.router.parseUrl('/select-tenant');
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
