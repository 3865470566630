import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeleteMscacheGuard implements CanActivate {
  canActivate(): Observable<boolean | UrlTree> {
    for (const key in localStorage) {
      if (this.containsMSSpecificWords(key)) {
        localStorage.removeItem(key);
      }
    }
    console.log('MSCache Deleted');
    return of(true);
  }

  containsMSSpecificWords(key: string) {
    return key.includes('login.windows');
  }
}
