export type DurationUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks';

export class Duration {
  constructor(
    /**
     * The value of the duration in milliseconds.
     */
    readonly value: number
  ) {}

  private static _conversionFactors: { [key in DurationUnit]: number } = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
    weeks: 1000 * 60 * 60 * 24 * 7,
  };

  static from(value: number, unit: DurationUnit) {
    return new Duration(value * this._conversionFactors[unit]);
  }

  static fromMilliseconds(value: number) {
    return Duration.from(value, 'milliseconds');
  }

  static fromSeconds(value: number) {
    return Duration.from(value, 'seconds');
  }

  static fromMinutes(value: number) {
    return Duration.from(value, 'minutes');
  }

  static fromHours(value: number) {
    return Duration.from(value, 'hours');
  }

  static fromDays(value: number) {
    return Duration.from(value, 'days');
  }

  static fromWeeks(value: number) {
    return Duration.from(value, 'weeks');
  }

  get largestUnitWithoutRemainder(): DurationUnit {
    if (this.value % Duration._conversionFactors.weeks === 0) {
      return 'weeks';
    }
    if (this.value % Duration._conversionFactors.days === 0) {
      return 'days';
    }
    if (this.value % Duration._conversionFactors.hours === 0) {
      return 'hours';
    }
    if (this.value % Duration._conversionFactors.minutes === 0) {
      return 'minutes';
    }
    return 'seconds';
  }

  in(unit: DurationUnit, round = true) {
    const value = this.value / Duration._conversionFactors[unit];
    if (round) {
      return Math.round(value);
    }
    return value;
  }

  get inMilliseconds() {
    return this.value;
  }

  get inSeconds() {
    return this.in('seconds');
  }

  get inMinutes() {
    return this.in('minutes');
  }

  get inHours() {
    return this.in('hours');
  }

  get inDays() {
    return this.in('days');
  }

  get inWeeks() {
    return this.in('weeks');
  }
}
