import { Injectable } from '@angular/core';
import { AppConfigService } from '@tremaze/shared/util-app-config';

@Injectable({ providedIn: 'root' })
export class JsonSerializer {
  constructor(private appConfigService: AppConfigService) {}

  objectToJson(data: any) {
    return JSON.stringify(data, (key, value) => {
      if (
        ['meta', ...(this.appConfigService.jsonIgnoreFields ?? [])]?.includes(
          key
        )
      ) {
        return undefined;
      } else {
        if (value instanceof Date) {
          return value.getTime();
        }
        return value;
      }
    });
  }
}
