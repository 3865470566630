import { Component, OnInit } from '@angular/core';

@Component({
  template: ` <p>notification works!</p> `,
  styles: [],
})
export class NotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
