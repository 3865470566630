import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthV2Service } from './auth-service';

@Injectable({ providedIn: 'root' })
export class AuthV2Guard implements CanActivate, CanLoad {
  constructor(private authService: AuthV2Service, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.authenticated$.pipe(
      take(1),
      map((r) => (r ? true : this.router.createUrlTree(['/login'])))
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
