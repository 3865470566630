import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthV2Service } from './auth-service';

@Injectable({ providedIn: 'root' })
export class NoAuthV2Guard implements CanActivate {
  constructor(private authService: AuthV2Service, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.notAuthenticated$.pipe(
      take(1),
      map((r) => (r ? true : this.router.createUrlTree([''])))
    );
  }
}
