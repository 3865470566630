import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieStorage implements Storage {
  constructor(private cookieService: CookieService) {}

  [name: string]: any;

  get length() {
    return Object.keys(this.cookieService.getAll()).length;
  }

  clear(): void {
    this.cookieService.removeAll();
  }

  getItem(key: string): string | null {
    return this.cookieService.get(key);
  }

  key(index: number): string | null {
    const all = Object.keys(this.cookieService.getAll());
    if (all.length > index) {
      return all[index];
    }
    return null;
  }

  removeItem(key: string): void {
    this.cookieService.remove(key);
  }

  setItem(key: string, value: string): void {
    this.cookieService.put(key, value);
  }
}
