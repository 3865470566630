import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import {
  Address,
  ContactInfo,
  Equatable,
  Meta,
  TremazeTheme,
  UPDocker,
} from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import {
  FormBuildable,
  FormInputConfig,
} from '@tremaze/shared/ui/form-builder/types';

@staticImplements<Deserializable<Institution>>()
export class Institution implements Equatable {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public name?: string,
    public address?: Address,
    public contact?: ContactInfo,
    public logo?: FileStorage,
    public favicon?: FileStorage,
    public titleImage?: FileStorage,
    public theme?: TremazeTheme,
    public contactMail?: string,
    public displayMail?: string,
    public sort?: number,
    public tenantId?: string,
    public subdomain?: string,
    public description?: string,
    public disableTeamMemberPagination?: boolean,
    // LEGACY STUFF
    public invisibleSections: InstitutionSections[] = [],
    public upDockerImage?: UPDocker,
    readonly dataPrivacy?: string,
    readonly imprint?: string,
    readonly checkInMaxVisitors?: number,
    readonly teaserText?: string,
    readonly teaserImage?: FileStorage,
    // END LEGACY STUFF
    readonly isPublic?: boolean,
    readonly registrationMail?: string,
    readonly mySpaceHeaderImage?: FileStorage
  ) {}

  static deserialize(data: any): Institution {
    return !data
      ? null
      : new Institution(
          data.id,
          Meta.deserialize(data),
          data.name,
          Address.deserialize(data.address),
          ContactInfo.deserialize(data.contact ?? data),
          FileStorage.deserialize(data.logo),
          FileStorage.deserialize(data.favicon),
          FileStorage.deserialize(data.titleImage),
          {
            accentColor: data.accentColor,
            accentForegroundColor: data.accentForegroundColor,
            primaryColor: data.primaryColor,
            primaryForegroundColor: data.primaryForegroundColor,
            tertiaryColor: data.tertiaryColor,
            tertiaryForegroundColor: data.tertiaryForegroundColor,
            warnColor: data.warnColor,
            warnForegroundColor: data.warnForegroundColor,
          },
          data.contactMail,
          data.displayMail,
          data.sort,
          data.tenantId,
          data.subdomain,
          data.description,

          data.disableTeamMemberPagination,

          // LEGACY STUFF
          data.invisibleSections,
          data.upDockerImage,
          data.dataPrivacy,
          data.imprint,
          data.checkInMaxVisitors,
          data.teaserText,
          FileStorage.deserialize(data?.teaserImage),
          data.public ?? false,
          data.registrationMail,
          FileStorage.deserialize(data.mySpaceHeaderImage)
        );
  }

  static compareWith = (inst: Institution, inst2: Institution) =>
    inst?.id === inst2.id;

  equals(other: Institution): boolean {
    return this.id === other.id;
  }

  get initials(): string {
    const split = this.name
      .split(' ')
      .map((s) => s.trim())
      .filter((s) => s.length);
    const r = [];
    for (let i = 0; i <= Math.min(split.length - 1, 1); i++) {
      r.push(split[i].charAt(0).toUpperCase());
    }
    return r.join('');
  }
}

export type InstitutionSections =
  | 'WE_OFFER_YOU'
  | 'WEEK_PLAN'
  | 'HIGHLIGHTS'
  | 'TEAM'
  | 'APPROACH_AND_TIMES'
  | 'CONTACT'
  | 'NEWS'
  | 'NEXT_EVENT'
  | 'PARTNERS';

@staticImplements<Deserializable<InstitutionCompetence>>()
export class InstitutionCompetence extends FormBuildable {
  constructor(
    readonly id: string = null,
    readonly meta?: Meta,
    public title?: string,
    public text?: string,
    public instId?: string,
    public competenceImage?: FileStorage,
    private sort?: number,
    public iconName?: string
  ) {
    super();
  }

  get orderIndex() {
    return this.sort;
  }

  set orderIndex(val) {
    this.sort = val;
  }

  public static deserialize(obj: any): null | InstitutionCompetence {
    return !obj || obj instanceof InstitutionCompetence
      ? obj
      : new InstitutionCompetence(
          obj.id,
          Meta.deserialize(obj.meta),
          obj.title,
          obj.text,
          obj.instId,
          FileStorage.deserialize(obj.competenceImage),
          obj.sort,
          obj.iconName
        );
  }

  shouldFormBuilderIgnoreField(key: keyof this): boolean {
    return (<(keyof this)[]>['iconName', 'instId', 'sort']).includes(key);
  }

  protected getFormInputConfigForKey(key: keyof this): FormInputConfig {
    switch (key) {
      case 'title':
        return {
          label: 'Titel',
          required: true,
          maxLength: 20,
        };
      case 'text':
        return {
          label: 'Text',
          required: true,
          inputType: 'TEXTAREA',
          maxLength: 140,
        };
      case 'competenceImage':
        return {
          label: 'Bild',
          required: true,
          inputType: 'IMAGE',
          fileSelectorConfig: { aspectRatio: 1, entity: 'INST_COMPETENCE' },
        };
    }
  }
}

@staticImplements<Deserializable<InstitutionTeamMember>>()
export class InstitutionTeamMember extends FormBuildable {
  constructor(
    readonly id: string = null,
    readonly meta?: Meta,
    public image?: FileStorage,
    public instId?: string,
    public firstname?: string,
    public lastname?: string,
    public upDescription?: string,
    public sort?: number
  ) {
    super();
  }

  static deserialize(data: any): null | InstitutionTeamMember {
    return !data
      ? null
      : new InstitutionTeamMember(
          data.id,
          Meta.deserialize(data.meta),
          FileStorage.deserialize(data.image),
          data.instId,
          data.firstname,
          data.lastname,
          data.upDescription,
          data.sort
        );
  }

  protected getFormInputConfigForKey(key: keyof this): FormInputConfig {
    switch (key) {
      case 'image':
        return {
          required: true,
          inputType: 'IMAGE',
          label: 'Profilild',
          fileSelectorConfig: { entity: 'EXTERN_TEAM_MEMBER', aspectRatio: 1 },
        };
      case 'firstname':
        return { required: true, label: 'Vorname' };
      case 'lastname':
        return { required: true, label: 'Nachname' };
      case 'upDescription':
        return {
          required: true,
          label: 'Beschreibung',
          inputType: 'RICH_TEXT',
        };
    }
  }

  protected shouldFormBuilderIgnoreField(key: keyof this): boolean {
    return (<(keyof this)[]>['instId', 'sort']).includes(key);
  }
}

@staticImplements<Deserializable<InstitutionPartner>>()
export class InstitutionPartner {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public image?: FileStorage,
    public title?: string,
    public text?: string,
    public sort?: number
  ) {}

  static deserialize(data: any): null | InstitutionPartner {
    return !data
      ? null
      : new InstitutionPartner(
          data.id,
          Meta.deserialize(data.meta),
          FileStorage.deserialize(data.image),
          data.title,
          data.text,
          data.sort
        );
  }
}
