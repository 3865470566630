import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LocalStorage implements Storage {
  get length(): number {
    return this.storage?.length || 0;
  }

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  [name: string]: any;

  private get storage() {
    return isPlatformBrowser(this.platformId) ? localStorage : null;
  }

  clear(): void {
    this.storage?.clear();
  }

  getItem(key: string): string | null {
    return this.storage?.getItem(key);
  }

  key(index: number): string | null {
    return this.storage?.key(index);
  }

  removeItem(key: string): void {
    return this.storage?.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage?.setItem(key, value);
  }

  keys(): string[] {
    return Object.keys(this.storage);
  }
}
