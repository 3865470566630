import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Address, ContactInfo, Meta } from '@tremaze/shared/models';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { UserType } from '@tremaze/shared/feature/user/types';
import { AuthenticatedUserTenant } from './authenticated-user-tenant';

@staticImplements<Deserializable<AuthenticatedUser>>()
export class AuthenticatedUser {
  constructor(
    readonly userId: string,
    readonly meta: Meta,
    readonly username: string,
    readonly email?: string,
    readonly enabled?: boolean,
    readonly mainAdmin?: boolean,
    readonly userRoles?: string[],
    readonly userPrivileges?: string[],
    readonly firstName?: string,
    readonly lastName?: string,
    readonly contact?: ContactInfo,
    readonly gender?: Gender,
    readonly userInstitutions: UserInstitution[] = [],
    readonly profileImage?: FileStorage,
    readonly address?: Address,
    readonly birth?: TremazeDate,
    readonly userTypes?: UserType[],
    public tenants?: AuthenticatedUserTenant[]
  ) {}

  get instIds(): string[] {
    return this.userInstitutions?.map((u) => u.institution?.id) ?? [];
  }

  get initials(): string {
    const arr = [];
    if (this.firstName?.length) {
      arr.push(this.firstName[0].toUpperCase());
    }
    if (this.lastName?.length) {
      arr.push(this.lastName[0].toUpperCase());
    }
    return arr.join('');
  }

  static deserialize(data: any): AuthenticatedUser {
    return !data
      ? null
      : new AuthenticatedUser(
          data.userId,
          Meta.deserialize(data.meta),
          data.username,
          data.email,
          data.enabled,
          data.mainAdmin,
          data.userRoles,
          data.userPrivileges,
          data.firstname || data.firstName,
          data.lastname || data.lastName,
          ContactInfo.deserialize(
            data.contact ?? {
              mobile: data.mobile,
              email: data.email,
              phone: data.phone,
            }
          ),
          Gender.deserialize(data.gender),
          data.userInstitutionPrivileges,
          FileStorage.deserialize(data.profileImage ?? data.avatar),
          Address.deserialize(data.address),
          TremazeDate.deserialize(data.birth),
          data.userTypes
        );
  }
}
