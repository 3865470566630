export function fileListToFileArray(fl: FileList): File[] {
  const res = [];
  for (let i = 0; i < fl.length; i++) {
    res.push(fl.item(i));
  }
  return res;
}

export function blobToFile(
  theBlob: Blob,
  fileName: string,
  fileType: string
): File {
  return new File([theBlob], fileName, {
    type: fileType,
    lastModified: new Date().getTime(),
  });
}

export function isFunction(f) {
  return f && {}.toString.call(f) === '[object Function]';
}

export const isBrowser = (function () {
  return typeof window === 'undefined';
})();

export function isNotNullOrUndefined<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function isNotEmpty(value: any): boolean {
  if (!value) {
    return false;
  } else if (Array.isArray(value)) {
    return !!value.length;
  } else if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }
  return true;
}

export function isEmpty(value: any): boolean {
  return !isNotEmpty(value);
}

export function moveInArray(array: unknown[], from: number, to: number): void {
  const element = array[from];
  array.splice(from, 1);
  array.splice(to, 0, element);
}

export function replaceUmlauts(str: string): string {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(
      new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'),
      (a) => umlautMap[a]
    );
}

export function deepEqual(object1, object2) {
  const object1NullOrUndefined = object1 === null || object1 === undefined;
  const object2NullOrUndefined = object2 === null || object2 === undefined;
  if (object1NullOrUndefined || object2NullOrUndefined) {
    return object1NullOrUndefined === object2NullOrUndefined;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}

/**
 * Returns the value if it is not null, otherwise returns undefined.
 * @param value
 */
export function ensureUndefinedOrValue<T>(
  value: T | null | undefined
): T | undefined {
  return value === null ? undefined : value;
}

export function ensureArray<T>(value: T | T[]): T[] {
  if (value === null || value === undefined) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

export function ensureNotArray<T>(value: T | T[]): T {
  if (value === null || value === undefined) {
    return null;
  }
  return Array.isArray(value) ? value[0] : value;
}
