import { forwardRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Tenant,
  TenantNews,
  TenantProject,
} from '@tremaze/shared/feature/tenant/types';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { Role } from '@tremaze/shared/permission/types';

interface EditDTO {
  accessibilityText: string;
  dataPrivacy: string;
  description: string;
  eventAgreement: string;
  imprint: string;
  logoId: string;
  mailImprint: string;
  titleImageId: string;
  fakeAccountsAreDefault: boolean;
  appWelcomeText: string;
  deleteAccountRequestEmail: string;
}

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => RemoteTenantDataSource),
})
export abstract class TenantDataSource {
  abstract getTenant(id?: string): Observable<Tenant>;
}

@Injectable({ providedIn: 'root' })
export class RemoteTenantDataSource implements TenantDataSource {
  protected controller = '/cc/tenants/current';

  constructor(protected http: HttpClient) {}

  getTenant(id?: string): Observable<Tenant> {
    const httpOptions = id
      ? { params: { skipTenantId: true }, headers: { 'X-TenantID': id } }
      : {};
    return this.http
      .get(`/cc/users/tenants/current`, httpOptions)
      .pipe(map(Tenant.deserialize));
  }

  setTenantTitleImage(image?: FileStorage): Observable<boolean> {
    return this.getTenant().pipe(
      switchMap((tenant) => {
        tenant.titleImage = image;
        return this.setTenant(tenant).pipe(map((r) => r instanceof Tenant));
      })
    );
  }

  setTenantLogo(image: FileStorage): Observable<boolean> {
    return this.getTenant().pipe(
      switchMap((tenant) => {
        tenant.logo = image;
        return this.setTenant(tenant).pipe(map((r) => r instanceof Tenant));
      })
    );
  }

  setDefaultRoles(roles: Role[]): Observable<boolean> {
    return this.http
      .put('/cc/users/tenants/current/defaultRoles', null, {
        params: { roleIds: roles.map((r) => r.id) },
      })
      .pipe(mapTo(true));
  }

  setFakeAccountDefaultSetting(
    isFakeAccountDefault: boolean
  ): Observable<boolean> {
    return this.getTenant().pipe(
      switchMap((tenant) => {
        tenant.fakeAccountsAreDefault = isFakeAccountDefault;
        return this.setTenant(tenant).pipe(map((r) => r instanceof Tenant));
      })
    );
  }

  setWelcomeText(welcomeText: string): Observable<boolean> {
    return this.getTenant().pipe(
      switchMap((tenant) => {
        tenant.appWelcomeText = welcomeText;
        return this.setTenant(tenant).pipe(map((r) => r instanceof Tenant));
      })
    );
  }

  setDeleteAccountRequestEmail(email: string): Observable<boolean> {
    return this.getTenant().pipe(
      switchMap((tenant) => {
        tenant.deleteAccountRequestEmail = email;
        return this.setTenant(tenant).pipe(map((r) => r instanceof Tenant));
      })
    );
  }

  private setTenant(tenant: Tenant): Observable<Tenant> {
    const payload: EditDTO = {
      dataPrivacy: tenant.dataPrivacy,
      accessibilityText: tenant.accessibilityText,
      description: tenant.description,
      logoId: tenant.logo?.id,
      titleImageId: tenant.titleImage?.id,
      eventAgreement: tenant.eventAgreement,
      imprint: tenant.imprint,
      mailImprint: tenant.mailImprint,
      fakeAccountsAreDefault: tenant.fakeAccountsAreDefault,
      appWelcomeText: tenant.appWelcomeText,
      deleteAccountRequestEmail: tenant.deleteAccountRequestEmail,
    };
    return this.http
      .put<TremazeHttpResponse<Tenant>>(this.controller, payload)
      .pipe(map((r) => Tenant.deserialize(r.object)));
  }
}

@Injectable({ providedIn: 'root' })
export class RemotePublicTenantDataSource implements TenantDataSource {
  protected controller = '/public/tenant';

  constructor(private http: HttpClient) {}

  getTenant(): Observable<Tenant> {
    return this.http
      .get(`${this.controller}/get`)
      .pipe(map(Tenant.deserialize));
  }

  getTenantNews(): Observable<TenantNews[]> {
    return this.http.get<TenantNews[]>('/public/tenantNews/all');
  }

  getTenantProjects(): Observable<TenantProject[]> {
    return this.http
      .get<TenantProject[]>('/public/tenant/getTenantProjects')
      .pipe(
        map((data) => data.map((project) => TenantProject.deserialize(project)))
      );
  }
}
