import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import {
  ConfirmationService,
  ConfirmationServiceImpl,
} from './confirmation.service';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  declarations: [ConfirmationComponent],
  providers: [
    {
      provide: ConfirmationService,
      useClass: ConfirmationServiceImpl,
    },
  ],
})
export class SharedFeatureConfirmationModule {}
