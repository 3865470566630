import { Meta } from '@tremaze/shared/models';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { PrivilegeName, Role } from '@tremaze/shared/permission/types';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<UserInstitution>>()
export class UserInstitution {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public institution?: Institution,
    public roles?: Role[]
  ) {
    if (roles?.length) {
      const result = new Set<PrivilegeName>();
      for (const role of roles) {
        for (const priv of role.privileges) {
          result.add(priv.name);
        }
      }
      this._distinctPrivileges = result;
    }
  }

  static deserialize(data: any): null | UserInstitution {
    return !data
      ? null
      : new UserInstitution(
          data.id,
          Meta.deserialize(data),
          Institution.deserialize(data.institution),
          (data.roles ?? data.instRoles)?.map(Role.deserialize)
        );
  }

  private _distinctPrivileges: Set<PrivilegeName>;

  get distinctPrivileges(): Set<PrivilegeName> {
    return this._distinctPrivileges ?? new Set<PrivilegeName>();
  }
}
