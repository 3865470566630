import { TremazeHttpResponseStatus } from '@tremaze/shared/util-http/types';

export class Exception {
  constructor(public status: TremazeHttpResponseStatus = 'ERROR') {
    Error.apply(this);
  }
}

// Auth Exceptions
export class CredentialsException extends Exception {}

export class NotApprovedException extends Exception {}

export class RefreshTokenExpiredException extends Exception {}
