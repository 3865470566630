export abstract class Failure {}

// General failures
export class ServerFailure extends Failure {}

export class CanceledFailure extends Failure {}

// Auth failures
export class CredentialsFailure extends Failure {}

export class RefreshTokenExpiredFailure extends Failure {}

//
export class EmailAlreadyInUseFailure extends Failure {}

export class UsernameAlreadyInUseFailure extends Failure {}

export class UsernameOrEmailAlreadyInUseFailure extends Failure {}

export class PasswordIsNotSecureFailure extends Failure {}

export class MobileAlreadyInUseFailure extends Failure {}

export class PasswordDoesNotMeetRequirementsFailure extends Failure {}

export class UserNotFoundFailure extends Failure {}

export class UserHasNoEmailFailure extends Failure {}

export class EventNotFoundFailure extends Failure {}

export class YouAreNotAPartOfTheEventFailure extends Failure {}

export class ParentNotFoundFailure extends Failure {}

export class NotFoundFailure extends Failure {}

export class ChildNotFoundFailure extends Failure {}

export class InstitutionDoesNotExistFailure extends Failure {}

export class EventTemplateNotFoundFailure extends Failure {}

export class EventTemplateDeletedBecauseOfEmptyInstitutionsFailure extends Failure {}

export class NewsDeletedBecauseOfEmptyInstitutionsFailure extends Failure {}

export class InstNeededFailure extends Failure {}

export class NewsNotFoundFailure extends Failure {}

export class ChildInstitutionDoesNotMatchInstitutionFailure extends Failure {}

export class NotAnImageFailure extends Failure {}

export class LinkExpiredFailure extends Failure {}
