import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Pagination, TableObject } from '@tremaze/shared/models';
import {
  ChildInstitutionDoesNotMatchInstitutionFailure,
  ChildNotFoundFailure,
  EmailAlreadyInUseFailure,
  EventNotFoundFailure,
  EventTemplateDeletedBecauseOfEmptyInstitutionsFailure,
  EventTemplateNotFoundFailure,
  Failure,
  InstitutionDoesNotExistFailure,
  InstNeededFailure,
  LinkExpiredFailure,
  MobileAlreadyInUseFailure,
  NewsDeletedBecauseOfEmptyInstitutionsFailure,
  NewsNotFoundFailure,
  NotAnImageFailure,
  NotFoundFailure,
  ParentNotFoundFailure,
  PasswordDoesNotMeetRequirementsFailure,
  PasswordIsNotSecureFailure,
  ServerFailure,
  UserHasNoEmailFailure,
  UsernameAlreadyInUseFailure,
  UsernameOrEmailAlreadyInUseFailure,
  UserNotFoundFailure,
  YouAreNotAPartOfTheEventFailure,
} from '@tremaze/shared/util-error';
import { TremazeHttpResponseStatus } from '@tremaze/shared/util-http/types';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

export type SortDirection = 'asc' | 'desc' | '';

export abstract class SortedFilteredPaginatedListParams {
  sort?: string;
  sortDirection?: SortDirection;
  filterFields?: any[];
  filterValue?: string;
  page?: number;
  pageSize?: number;
  additionalParams?: {};
  unpaged?: boolean;

  static toHttpParams(s: SortedFilteredPaginatedListParams): {
    [key: string]: string;
  } {
    if (!s) {
      return {};
    }
    let result = {};
    if (s.pageSize > 0 && typeof s.page === 'number' && s.page >= 0) {
      result = {
        ...result,
        ...{
          page: s.page,
          size: s.pageSize,
        },
      };
    }
    if (s.sort?.length && s.sortDirection?.length) {
      result = {
        ...result,
        ...{ sort: `${s.sort},${s.sortDirection}` },
      };
    }
    if (s.filterValue && s.filterFields?.length) {
      result = {
        ...result,
        ...{
          filterFields: s.filterFields.join(','),
          filterValue: `%${s.filterValue}%`,
        },
      };
    }
    if (s?.unpaged) {
      s.additionalParams = {
        ...(s.additionalParams || {}),
        unpaged: true,
      };
    }
    if (typeof s.additionalParams === 'object') {
      const notNullParams = {};
      Object.keys(s.additionalParams).forEach((k) => {
        if (s.additionalParams[k]) {
          notNullParams[k] = s.additionalParams[k];
        }
      });

      result = {
        ...result,
        ...notNullParams,
      };
    }
    return result;
  }
}

export function mapTremazeHttpStatusToFailure(
  status: TremazeHttpResponseStatus
): Failure {
  switch (status) {
    case 'SUCCESS':
      return undefined;
    case 'ERROR':
      return new ServerFailure();
    case 'EMAIL_ALREADY_IN_USE':
      return new EmailAlreadyInUseFailure();
    case 'USERNAME_ALREADY_IN_USE':
      return new UsernameAlreadyInUseFailure();
    case 'USERNAME_OR_EMAIL_ALREADY_IN_USE':
      return new UsernameOrEmailAlreadyInUseFailure();
    case 'USER_NOT_FOUND':
      return new UserNotFoundFailure();
    case 'USER_DOES_NOT_HAVE_AN_EMAIL':
      return new UserHasNoEmailFailure();
    case 'MOBILE_ALREADY_IN_USE':
      return new MobileAlreadyInUseFailure();
    case 'PASSWORD_NOT_VALID':
      return new PasswordDoesNotMeetRequirementsFailure();
    case 'EVENT_NOT_FOUND':
      return new EventNotFoundFailure();
    case 'YOU_ARE_NOT_A_PART_OF_THE_EVENT':
      return new YouAreNotAPartOfTheEventFailure();
    case 'PARENT_NOT_FOUND':
      return new ParentNotFoundFailure();
    case 'CHILD_NOT_FOUND':
      return new ChildNotFoundFailure();
    case 'INSTITUTION_DOES_NOT_EXISTS':
      return new InstitutionDoesNotExistFailure();
    case 'EVENT_TEMPLATE_NOT_FOUND':
      return new EventTemplateNotFoundFailure();
    case 'EVENT_TEMPLATE_DELETED_BECAUSE_OF_EMPTY_INSTS':
      return new EventTemplateDeletedBecauseOfEmptyInstitutionsFailure();
    case 'NEWS_DELETED_BECAUSE_OF_EMPTY_INSTS':
      return new NewsDeletedBecauseOfEmptyInstitutionsFailure();
    case 'INST_NEEDED':
      return new InstNeededFailure();
    case 'NEWS_NOT_FOUND':
      return new NewsNotFoundFailure();
    case 'CHILD_INST_DOES_NOT_MATCH_INST':
      return new ChildInstitutionDoesNotMatchInstitutionFailure();
    case 'NOT_AN_IMAGE':
      return new NotAnImageFailure();
    case 'LINK_EXPIRED':
      return new LinkExpiredFailure();
    case 'PASSWORD_IS_NOT_SECURE':
      return new PasswordIsNotSecureFailure();
    default:
      return new ServerFailure();
  }
}

export const jsonHttpHeaders = new HttpHeaders({
  'Content-Type': 'application/json',
});
export const urlEncodedHttpHeaders = new HttpHeaders({
  'Content-Type': 'application/x-www-form-urlencoded',
});

export const formDataHttpHeaders = new HttpHeaders({
  'Content-Type': 'multipart/form-data',
});

export type Deserializer<T> = (data: any) => T | null;

export type Serializer<T> = (d: T) => string;

export type HTTPMethods = 'POST' | 'PUT' | 'DELETE' | 'GET';

export interface DataSourceMethodsBaseOptions {
  q?: qry;
  method?: HTTPMethods;
  endpoint?: string;
  controller?: string;
  instIds?: string[];
  departmentIds?: string[];
}

export type DataSourceReadOptions<T> = DataSourceMethodsBaseOptions & {
  deserializer?: Deserializer<T>;
  overrideController?: string;
};

export type DataSourceMethodsGetFreshOptions<T = any> =
  DataSourceReadOptions<T>;

export interface DataSourceMethodsCreateOptions<T>
  extends DataSourceReadOptions<T> {
  serializer?: Serializer<T>;
}

export type DataSourceMethodsEditOptions<T> = DataSourceMethodsCreateOptions<T>;

export type DataSourceMethodsDeleteOptions = DataSourceMethodsBaseOptions;

export interface DataSourceMethodsPaginatedOptions<T = any>
  extends DataSourceReadOptions<T> {
  filter?: SortedFilteredPaginatedListParams;
}

export abstract class DefaultDataSourceMethods {
  static create<T>(
    http: HttpClient,
    controller: string,
    deserializer: Deserializer<T>,
    js: JsonSerializer,
    i: T,
    options?: DataSourceMethodsCreateOptions<T>
  ): Observable<T> {
    return http
      .request<any>(
        options?.method || 'POST',
        `${options?.controller || controller}${options?.endpoint || ''}`,
        {
          body: options?.serializer?.(i) || js.objectToJson(i),
          headers: jsonHttpHeaders,
          params: DefaultDataSourceMethods.createHTTPParams(options),
        }
      )
      .pipe(map((r) => deserializer(r.object || r)));
  }

  static getFreshById<T>(
    http: HttpClient,
    controller: string,
    deserializer: Deserializer<T>,
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T> {
    return http
      .request<any>(
        options?.method || 'GET',
        `${options?.controller || controller}${options?.endpoint || ''}/${id}`,
        {
          params: DefaultDataSourceMethods.createHTTPParams(options),
        }
      )
      .pipe(
        map((i) => {
          if (i?.message === 'Requested dataset not found') {
            throw new NotFoundFailure();
          }
          return deserializer(i);
        })
      );
  }

  static getPaginated<T>(
    http: HttpClient,
    controller: string,
    deserializer: Deserializer<T>,
    options?: DataSourceMethodsPaginatedOptions,
    withGlobalFlag?: boolean
  ): Observable<Pagination<T>> {
    options ??= {};
    options.filter ??= {};
    options.filter.pageSize ??= 100;
    options.filter.page ??= 0;
    return http
      .request<Pagination<T> | TableObject<T>>(
        options?.method || 'GET',
        `${options?.controller || controller}${options?.endpoint || ''}`,
        {
          params: DefaultDataSourceMethods.createHTTPParams(
            options,
            withGlobalFlag
          ),
        }
      )
      .pipe(
        map((i: any) =>
          Pagination.deserialize(
            i.content ? i : { content: i.items, totalElements: i.count },
            deserializer
          )
        )
      );
  }

  static edit<T>(
    http: HttpClient,
    controller: string,
    deserializer: Deserializer<T>,
    js: JsonSerializer,
    i: T,
    options: DataSourceMethodsEditOptions<T> = { method: 'PUT' }
  ): Observable<T> {
    return this.create(http, controller, deserializer, js, i, {
      method: 'PUT',
      ...(options || {}),
    });
  }

  static deleteById(
    http: HttpClient,
    controller: string,
    id: string,
    options?: DataSourceMethodsDeleteOptions
  ): Observable<boolean> {
    return http
      .request<true>(
        options?.method || 'DELETE',
        `${options?.controller || controller}${options?.endpoint || ''}/${id}`,
        {
          params: DefaultDataSourceMethods.createHTTPParams(options),
        }
      )
      .pipe(map(() => true));
  }

  public static createHTTPParams(
    options: DataSourceMethodsBaseOptions,
    withGlobalFlag?: boolean
  ): null | HttpParams {
    if (!options) {
      if (withGlobalFlag) {
        return new HttpParams({ fromObject: { global: 'true' } });
      }
      return null;
    }
    const { q, instIds, departmentIds, filter } =
      options as DataSourceMethodsPaginatedOptions;

    let r: HttpParams;
    if (q instanceof HttpParams) {
      r = q;
    } else {
      r = new HttpParams({ fromObject: q });
    }

    if (instIds?.length) {
      r = r.append('instIds', instIds.join(','));
    }

    if (departmentIds?.length) {
      r = r.append('departmentIds', departmentIds.join(','));
    }

    if (withGlobalFlag && !instIds?.length && !departmentIds?.length) {
      r = r.append('global', 'true');
    }

    if (filter) {
      const filterPOJ = SortedFilteredPaginatedListParams.toHttpParams(filter);
      r = r.appendAll(filterPOJ);
    }

    return r;
  }
}

export abstract class BaseDataSource<T> {
  protected abstract deserializer: Deserializer<T>;
  protected abstract js: JsonSerializer;
  protected abstract http: HttpClient;
  protected abstract controller: string;
}

export interface DefaultREADDataSourceI<T> {
  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T>;

  getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<T>
  ): Observable<T[]>;
}

export abstract class DefaultREADDataSource<T>
  extends BaseDataSource<T>
  implements DefaultREADDataSourceI<T>
{
  abstract getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T>;

  getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<T>
  ): Observable<T[]> {
    return zip(
      ...ids.map((id) => this.getFreshById(id, options))
    ) as Observable<T[]>;
  }
}

export abstract class DefaultREADDataSourceImpl<T>
  extends BaseDataSource<T>
  implements DefaultREADDataSource<T>
{
  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<T>
  ): Observable<T> {

    return DefaultDataSourceMethods.getFreshById(
      this.http,
      this.controller,
      options?.deserializer ?? this.deserializer,
      id,
      options
    );
  }

  getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<T>
  ): Observable<T[]> {
    return zip(
      ...ids.map((id) => this.getFreshById(id, options))
    ) as Observable<T[]>;
  }
}

export interface DefaultREADDataSourceWithPaginationI<T>
  extends DefaultREADDataSourceI<T> {
  getPaginated(
    options?: DataSourceMethodsPaginatedOptions
  ): Observable<Pagination<T>>;
}

export abstract class DefaultREADDataSourceWithPagination<T>
  extends DefaultREADDataSource<T>
  implements DefaultREADDataSourceWithPaginationI<T>
{
  abstract getPaginated(
    options?: DataSourceMethodsPaginatedOptions
  ): Observable<Pagination<T>>;
}

export abstract class DefaultREADDataSourceWithPaginationImpl<T>
  extends DefaultREADDataSourceImpl<T>
  implements DefaultREADDataSourceWithPagination<T>
{
  protected filterFields?: string[];
  protected supportsGlobalFlag?: boolean;
  protected additionalQuery?: { [key: string]: string };

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<T>
  ): Observable<Pagination<T>> {
    return DefaultDataSourceMethods.getPaginated<T>(
      this.http,
      options?.overrideController ?? this.controller,
      options?.deserializer ?? this.deserializer,
      {
        ...options,
        q: {
          ...(options?.q ?? {}),
          ...this.additionalQuery,
        },
        filter: {
          ...(options?.filter ?? {}),
          filterFields: options?.filter?.filterFields ?? this.filterFields,
        },
      },
      this.supportsGlobalFlag
    );
  }
}

export interface DefaultCRUDDataSourceI<T>
  extends DefaultREADDataSourceWithPaginationI<T> {
  create(i: T, options?: DataSourceMethodsCreateOptions<T>): Observable<T>;

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T>;

  edit(i: T, options?: DataSourceMethodsEditOptions<T>): Observable<T>;

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions
  ): Observable<boolean>;
}

export abstract class DefaultCRUDDataSource<T>
  extends DefaultREADDataSourceWithPagination<T>
  implements DefaultCRUDDataSourceI<T>
{
  abstract create(
    i: T,
    options?: DataSourceMethodsCreateOptions<T>
  ): Observable<T>;

  abstract getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T>;

  abstract edit(i: T, options?: DataSourceMethodsEditOptions<T>): Observable<T>;

  abstract deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions
  ): Observable<boolean>;
}

interface HateoasLink {
  href: string;
  type: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

type HateoasLinks = { [key: string]: HateoasLink } & {
  self: HateoasLink;
};

export interface HateoasInputSuccessResponse {
  object: {
    id: string;
  };
  _links: HateoasLinks;
}

export interface HateoasEmbeddedInputSuccessResponse<T extends string> {
  _embedded: {
    _links: HateoasLinks;
  } & {
    [P in T]: HateoasLinks;
  };
}

/***
 * Default class for the new tremaze controller standard
 */
export abstract class DefaultCRUDDataSourceImpl<T>
  extends DefaultREADDataSourceWithPaginationImpl<T>
  implements DefaultCRUDDataSource<T>
{
  protected abstract deserializer: Deserializer<T>;
  protected abstract js: JsonSerializer;
  protected abstract http: HttpClient;
  protected abstract controller: string;
  protected serializer?: Serializer<T>;

  create(i: T, options?: DataSourceMethodsCreateOptions<T>): Observable<T> {
    return DefaultDataSourceMethods.create<T>(
      this.http,
      this.controller,
      options?.deserializer ?? this.deserializer,
      this.js,
      i,
      options
    );
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions
  ): Observable<T> {
    return super.getFreshById(id, options);
  }

  edit(i: T, options?: DataSourceMethodsEditOptions<T>): Observable<T> {
    return DefaultDataSourceMethods.edit<T>(
      this.http,
      this.controller,
      options?.deserializer ?? this.deserializer,
      this.js,
      i,
      options
    );
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions
  ): Observable<boolean> {
    return DefaultDataSourceMethods.deleteById(
      this.http,
      this.controller,
      id,
      options
    );
  }
}

export type qry =
  | HttpParams
  | {
      [param: string]: string | string[] | undefined;
    };

/***
 * Normalizes to URL to "https://....." (no "/" at the end)
 * @param url
 */
export function normalizeUrl(url: string) {
  if (!url) {
    return '';
  }
  let p = url;
  if (!p.startsWith('http')) {
    p = `https://${p}`;
  }
  if (p.endsWith('/')) {
    p = p.substring(0, p.length - 1);
  }
  return p;
}
