import { Injectable } from '@angular/core';
import { PermissionCheckService } from './shared-permission-services';
import { from, map, Observable, pipe, switchMap } from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import {
  PrivilegeName,
  TremazePermissionRequest,
  TzPermissionRequest,
} from '@tremaze/shared/permission/types';

export const generatePermissionRequest = (
  privilege: PrivilegeName,
  instId?: string
): TzPermissionRequest =>
  new TremazePermissionRequest(privilege).addSingleInstitutionSinglePermission(
    privilege,
    instId
  );

@Injectable({ providedIn: 'root' })
export class PermissionChecker {
  constructor(private permissionCheckService: PermissionCheckService) {}

  checkSinglePrivilegeForGlobalAndInstitutionForbidden$(
    privilege: PrivilegeName,
    inst$: Observable<Institution | string>
  ): Observable<boolean> {
    return this.checkSinglePrivilegeForGlobalAndInstitution$(
      privilege,
      inst$
    ).pipe(map((permission: boolean) => !permission));
  }

  checkSinglePrivilegeForGlobalAndInstitution$(
    privilege: PrivilegeName,
    inst$: Observable<Institution | string>
  ): Observable<boolean> {
    return inst$.pipe(
      this.mapToInstId(),
      switchMap(this.getPermissionCheckMapper(privilege))
    );
  }

  private mapToInstId() {
    return pipe(
      map((inst: Institution | string) =>
        inst instanceof Institution ? inst.id : inst
      )
    );
  }

  private getPermissionCheckMapper =
    (privilegeName: PrivilegeName) =>
    (instId: string, index: number): Observable<boolean> =>
      from(
        this.permissionCheckService.checkPermission$(
          generatePermissionRequest(privilegeName, instId)
        )
      );
}
