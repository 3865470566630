import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { TremazeDate } from '@tremaze/shared/util-date';

@Injectable({ providedIn: 'root' })
export class TremazeDateAdapter extends NativeDateAdapter {
  clone(date: TremazeDate): TremazeDate {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): TremazeDate {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    return new TremazeDate(TremazeDate.UTC(year, month, date));
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: string | number): Date | null {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str: string[] = value.split('.');
      if (
        str.length < 2 ||
        isNaN(+str[0]) ||
        isNaN(+str[1]) ||
        isNaN(+str[2])
      ) {
        return null;
      }
      return TremazeDate.utc(
        TremazeDate.UTC(Number(str[2]), Number(str[1]) - 1, Number(str[0]))
      );
    }
    const timestamp: number =
      typeof value === 'number' ? value : TremazeDate.parse(value);
    return isNaN(timestamp) ? null : TremazeDate.utc(timestamp);
  }
}
