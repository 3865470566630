import { InjectionToken } from '@angular/core';
import { AuthV2OIDCConfig } from './authv2-oidc-config';

export interface AuthV2ModuleConfig {
  useCookieStorage?: boolean;
  oidcConfig?: AuthV2OIDCConfig;
  whitelistedUserTypes?: string[];
  useOIDC?: boolean;
}

export const AuthV2ModuleConfigInjectionToken =
  new InjectionToken<AuthV2ModuleConfig>('AUTHV2MODULECONFIG');
