import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<TokenInfo>>()
export class TokenInfo {
  constructor(
    readonly access_token: string,
    readonly refresh_token: string,
    readonly expiration: number,
    readonly expires_in: number,
    readonly scope: string,
    readonly token_type: string
  ) {}

  static deserialize(data: any): TokenInfo {
    return !data
      ? null
      : new TokenInfo(
          data.access_token,
          data.refresh_token,
          data.expiration,
          data.expires_in,
          data.scope,
          data.token_type
        );
  }
}
